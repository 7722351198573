<template>
  <list-item :data="listItemData">
  </list-item>
</template>

<script>
import ListItem from './ListItem'

export default {
  components: {
    ListItem
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    listItemData () {
      return {
        ...this.data,
        BorderWidth: 0,
        BodyClass: [
          'py-6 px-6 flex-col md:flex-row',
          this.data.BodyClass
        ],
        TextClass: [
          'mb-4 text-center md:mb-0',
          this.data.TextClass
        ],
        Actions: [
          {
            Text: this.data.ButtonText,
            Type: this.data.ButtonType
          }
        ]
      }
    }
  }
}
</script>

<style>

</style>
